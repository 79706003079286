import { parse } from 'csv-parse';

/**
 * returns the columns from an xml as a string array
 * @params xml {String} the xml as a string
 * @returns {Array} the column names as an array
 * */
export const getColumnNames = async(csv) => {
    const recs = await getRecords(csv);
    return recs[0] ? recs[0] : [];
};

/**
 * returns the data from an xml as a string array
 * @params xml {String} the xml as a string
 * @returns {Array} the xml data rows as an array
 * */
export const getData = async(csv, delimiter = ';') => {
    const records = await getRecords(csv, delimiter);
    records.shift();
    return records;
};

const getRecords = async (csv, delimiter = ";") => {
    const records = [];
    return new Promise((resolve, reject) => {
        const parser = parse({
            delimiter: delimiter
        });
        // Use the readable stream api to consume records
        parser.on('readable', function(){
            let record;
            while ((record = parser.read()) !== null) {
                records.push(record);
            }
        });
        // Catch any error
        parser.on('error', function(err){
            console.error(err.message);
            reject();
        });
        // Test that the parsed records matched the expected records
        parser.on('end', function(){
            return resolve(records);
        });
        parser.write(csv);
        parser.end();
    })
}